
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.image-size {
    width: 96px;
    height: 96px;
}
.bold{
    font-weight: bold
}
.primaryColor {
    color: dodgerblue
}
.grey{;
    color: #313131
}

.large {
    font-size: 18px;
}


.medium {
    font-size: 14px;
}


.flex-row{
    display:flex;
        flex-direction:row
}

.small {
    font-size: 12px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sploa-officer-line{
    margin: 16px;

}
.sploa-header {
    font-size: 20px;
    font-weight: bold;
}

.sploa-header-2 {
    font-size: 18px;
    font-weight: bold;
}
.sploa-content{
  position: absolute;
  left: 50%; 
  transform: translateX(-50%);
  width: 80%;
}

.info-header {
    margin: 12px 0px
}
.sploa-page{

  border: 1px solid white;
  border-radius: 30px;
  width: 80vw;
  margin: auto;
  opacity: 90;
  background-color: white;
  margin-bottom: 40px;
}
.sploa-bg{
  z-index: -1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  width: 100vw;
  height:100vh;
  position:fixed;
  background-position: fixed;

}
.sploa-link-button{
  padding: 0 !important;
}

.sploa-home-main{
  margin-top: 0 !important;
  padding: 0 80px 40px 80px;
margin:auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: left;
}


.sploa-home-info-subtext {
    font-size: 12px;
    margin: 12px 0px
}

.sploa-home-important{
  color: rgb(238, 10, 10);
  font-size: 18px;
  margin: 12px 0px
}

.sploa-home-carousel{
  width: 60vw;
  margin: auto;
  padding: 20px 0px;
}
.sploa-agenda-edit{
  display: flex;
  justify-content: end;
  padding: 20px;
}
.sploa-agenda-header{
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sploa-agenda-body{
  margin:auto;
  width: 80vw;
  padding: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: left;
}

.sploa-informational-pages{
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}
.sploa-flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: auto;
    align-items: center;
}

.sploa-header-image{
  display: flex;
  justify-content: center;
  max-width:50vw;
  max-height:30vw;

  margin:auto;
}

.sploa-nav-item{
  color:black !important;
}

.sploa-nav-header-item{
  color:white !important;
}

.sploa-info-page{
  margin:auto;
  width: 80%;
  padding-bottom: 40px;
}

.sploa-form-page{
  margin:auto;
  width: 100%;
  padding-bottom: 40px;
}
.sploa-title-header-2 {
    font-size: 18px;
    font-weight: bold;
    margin: 40px auto;
}
.sploa-title-header {
    width: 80%;
    font-size: 18px;
    font-weight: bold;
    margin: 40px auto;
}

.sploa-body{
  margin:auto;
  width: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: left;
}

.sploa-login-body{
  margin:40px auto;
  width: 80%;
  display: flex;
  justify-content: center;
}
.sploa-year-header{
  font-size: 18px;
  font-weight: bold;
}
form label {font-weight:bold}

.sploa-minute-meeting-row{
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.sploa-minute-meeting-button{
  margin: 0 20px;
}

.sploa-form-item-header{
  font-weight: bold;
  font-size: 18px;

}

.sploa-form-item{
  margin-bottom: 24px;
}

.sploa-form-textarea{
}

.sploa-agenda-form{
  margin: 24px
}

.agenda-button{
  margin: 0 24px
}

.sploa-pdf-page {

    margin: 24px auto;
    width: 612px;
}

.sploa-covenant-li{
    margin-top:12px;
}

.sploa-margin-16{
    margin-top:16px;
}

.sploa-pdf-buttons{
    display:flex;
    justify-content:center;
    flex-direction:row;
}

.sploa-pdf-button {
    display: flex;
    justify-content: flex-end;
    margin: 12px;
}

.sploa-info-margin{
    margin-bottom: 16px
}